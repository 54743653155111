<template>
    <div class="detail-box" v-loading="pageLoading">
        <el-card header="采购基本信息" class="left-card" style="margin-bottom:20px;">
            <detail-row label-position="center" label-width="108px" background>
                <el-row>
                    <el-col :span="8">
                        <detail-row-item label="采购编号" >{{data.purchase_no}}</detail-row-item>
                    </el-col>
                    <el-col :span="16">
                        <detail-row-item label="采购描述">{{data.purchase_subject}}</detail-row-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <detail-row-item label="供应商名称">{{data.supply_company}}</detail-row-item>
                    </el-col>
                    <el-col :span="8">
                        <detail-row-item label="采购发布时间">{{data.create_at}}</detail-row-item>
                    </el-col>
                    <el-col :span="8">
                        <detail-row-item label="报价轮次">{{data.quote_price_num}}</detail-row-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <detail-row-item label="目标价">{{data.target_price}}</detail-row-item>
                    </el-col>
                    <el-col :span="16">
                        <detail-row-item label="采购文件">
                            <span v-for="(item,index) in files" :key="index" class="purchase-files">
                                <a :href="item.file_url" target="_blank">{{item.file_name}}</a>
                            </span>
                        </detail-row-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <detail-row-item label="报价截止时间">{{data.expire_time}}</detail-row-item>
                    </el-col>
                    <el-col :span="8">
                        <detail-row-item label="报价提交时间">{{data.target_price_time}}</detail-row-item>
                    </el-col>
                    <el-col :span="8">
                        <detail-row-item label="状态">
                            {{data.target_price_status}}
                        </detail-row-item>
                    </el-col>
                </el-row>
            </detail-row>
        </el-card>

        <el-card header="报价内容" class="left-card" style="margin-bottom:20px;">
            <div slot="header" class="clearfix">
                <span>报价内容</span>
                <el-button style="float: right; padding: 3px 0" type="text" @click="viewRecord()" v-if="data.target_price_status=='已报价'">查看报价记录</el-button>
            </div>

            <el-form
                :model="priceForm"
                ref="priceForm"
                :rules="priceForm.rules"
            >
                <el-table :data="priceForm.list" border style="width: 100%" class="list-table" v-loading="loading">
                    <el-table-column label="序号" type="index" width="56" align="center" />
                    <el-table-column prop="product_name" label="品名" align="center" />
                    <el-table-column prop="description" label="规格与描述" align="center" />
                    <el-table-column prop="purchase_num" label="数量"  align="center" width="90">
                        <template slot-scope="{row}">
                            <el-input v-model="row.purchase_num" class="input-align_center" size="small" disabled />
                            <div class="el-form-item__error"></div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="brand_name" label="品牌"  align="center" width="90">
                        <template slot-scope="{row}">
                            <el-input v-model="row.brand_name" class="input-align_center" size="small" />
                        </template>
                    </el-table-column>
                    <el-table-column prop="model_name" label="型号"  align="center" width="90">
                        <template slot-scope="{row}">
                            <el-input v-model="row.model_name" class="input-align_center" size="small" />
                        </template>
                    </el-table-column>
                    <el-table-column prop="is_discount" label="是否抵扣" align="center" width=110>
                        <template slot-scope="scope">
                            <el-form-item :prop="'list.' + scope.$index + '.is_discount'"
                                :rules="priceForm.rules.is_discount">
                                <el-select v-model="scope.row.is_discount" placeholder="请选择" clearable size="small">
                                    <el-option label="可抵扣" :value="1"> </el-option>
                                    <el-option label="不可抵扣" :value="0"> </el-option>
                                </el-select>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column prop="tax_rate" label="税率" align="center" width=116>
                        <template slot-scope="scope">
                            <el-form-item :prop="'list.' + scope.$index + '.tax_rate'"
                                :rules="priceForm.rules.tax_rate">
                                <el-input class="input-small input-align_center" size="small" placeholder="请输入" v-model.number="scope.row.tax_rate">
                                    <template slot="append">%</template>
                                </el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column prop="price" label="单价" align="center" width=110>
                        <template slot-scope="scope">
                            <el-form-item :prop="'list.' + scope.$index + '.price'"
                                :rules="priceForm.rules.price">
                                <el-input class="input-align_center" v-model="scope.row.price" placeholder="请输入" size="small"></el-input>
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column prop="total" label="总价（元）" align="center">
                        <template slot-scope="scope">
                            {{getPriceTotal(scope.row)}}
                        </template>
                    </el-table-column>
                </el-table>
            </el-form>
            <div class="upload-total">
                <div class="area-upload">
                    <p style="color:red; margin-bottom:16px">* 请上传报价附件（<a :href="templateUrl" class="downtemplate" target="_blank" style="margin-left:0;">下载报价模板</a>）</p>
                    <el-upload
                        action="/api/v1/upload/file"
                        :before-upload="beforeUpload"
                        :on-success="uploadSuccess"
                        :show-file-list="false">
                        <el-button type="primary" plain>上传报价附件</el-button>
                        <span slot="tip" class="el-upload__tip" style="margin-left:10px">
                        支持上传文件格式：excel，word，ppt，pdf，图片（jpg,png）,cad图和压缩包，且不超过8MB！</span>
                    </el-upload>

                    <ul class="upload-list">
                        <li v-for="(item,index) in priceFiles" :key="index">
                            <el-link @click="openFile(item.file_name,item.file_url)" type="primary">{{item.file_name}}</el-link>
                            <i class="el-icon-circle-close" @click="delFile(index)"></i>
                        </li>
                    </ul>
                </div>
                <div class="area-total" style="padding-right: 20px;">
                   总计金额（元）： <span>{{ totalPrice }}</span> 
                </div>
            </div>

            <div style="padding-top: 20px; text-align:center; padding-bottom:50px">
                <el-button type="primary" @click="saveDetail()" style="padding: 12px 60px;">保 存</el-button>
            </div>
        </el-card>
        
        <dialog-record :open.sync="open" :contractId="paramsId"></dialog-record>
    </div>
</template>
<script>
import DetailRow from '@/components/DetailRow/Row'
import DetailRowItem from '@/components/DetailRow/Item'
import DialogRecord from './DialogRecord'
import {getPriceDetail, createdPrice} from "@/api";
import { BigNumber } from 'bignumber.js'

export default {
    name: 'PriceDtail',
    components:{
        DetailRow,
        DetailRowItem,
        DialogRecord
    },
    data(){
        return {
            pageLoading: false,
            paramsId: '',
            data:{

            },
            files:[],
            rates:[
                {val:0, label:'0%'},
                {val:1, label:'1%'},
                {val:3, label:'3%'},
                {val:5, label:'5%'},
                {val:6, label:'6%'},
                {val:9, label:'9%'},
                {val:11, label:'11%'},
                {val:13, label:'13%'},
                {val:17, label:'17%'}
            ],
            priceForm:{
                list:[],
                rules:{
                    is_discount:{ required: true, message: "请选择是否抵扣", trigger: "change" },
                    tax_rate:[{ required: true, trigger: 'blur', validator: (rule, value, callback) => {
                        if(value===''){
                            return callback(new Error('请输入税率'));
                        } else {
                            if(value < 0 || value > 100){
                                return callback(new Error('税率区间为0~100'));
                            }
                            callback();
                        }
                    }}],
                    price: [
                        { required: true, trigger: 'change', validator: (rule, value, callback) => {
                            if(value===''){
                                return callback(new Error('请输入单价'));
                            } else {
                                if(isNaN(value) || value <= 0){
                                    return callback(new Error('请输入正确单价'));
                                }
                                const reg = /^\d+(\.\d{0,2})?$/
                                if(!reg.test(value)){
                                    return callback(new Error('仅限两位小数'));
                                }
                                callback();
                            }
                        }}
                    ]
                },
            },
            templateUrl:'',

            priceList:[],
            loading: false,
            fileList:[],
            open: false,
            priceFiles:[]
        }
    },
    computed:{
        totalPrice:function(){
            let totalPrice = '0.00';
            let list = this.priceForm.list

            this.priceForm.list.forEach(item => {
                if(item.total){
                    totalPrice = BigNumber(item.total).plus(BigNumber(totalPrice)).toFixed(2)
                }      
            });
            return totalPrice.toString();
        }
    },
    created(){
        this.paramsId = parseInt(this.$route.params && this.$route.params.id)
        this.getData()
    },
    methods: {
        getData(){
            getPriceDetail({contract_object_id:this.paramsId}).then((res)=>{
                const {data} = res;
                this.data = data.contract_detail
                this.templateUrl = data.download_template_urls;

                this.files = data.file_url || []
                this.priceFiles = data.target_price_file || []
                if(data.purchase_item){
                    this.priceForm.list = data.purchase_item.map(item=>{
                        item.brand_name = item.brand_name || ''
                        item.model_name = item.model_name || ''
                        item.total = item.total || ''
                        return item
                    })
                }
            }).finally(()=>{
                this.loading = false
            })
        },
        formatText(status){
            switch (parseInt(status)){
                case 0:
                    return '等待邀标'
                case 1:
                    return '等待接收'
                case 2:
                    return '部分报价'
                case 3:
                    return '全部报价'
            }
        },


        //计算每行总价
        getPriceTotal(row){
            let total = 0.00
            let num = row.purchase_num;
            let price = row.price
            const reg = /^\d+(\.\d{0,2})?$/
            if(isNaN(price) || price <= 0 || !reg.test(price)){
                total = ''
            } else {
                total = BigNumber(price).multipliedBy(num).toFixed(2)
            }
            row.total = total
            return total;
        },

        separateAry(){
            let list = []
            list =  this.priceForm.list.map(item=>{
                return {
                    product_item_id: item.id,
                    is_discount: item.is_discount,
                    tax_rate: item.tax_rate,
                    price: item.price,
                    brand_name: item.brand_name,
                    model_name: item.model_name
                }
            })
            return list
        },
        
        filterFilesId(){
            let ids = [];
            ids = this.priceFiles.map(item => {
                return item.file_id
            });
            return ids
        },
        saveDetail(){
            if(this.data.purchase_status===6){
                this.$alert('招标已作废', '提示', { type:'error'})
                return
            }
            this.$refs.priceForm.validate(valid => {
                if (valid) {
                    if(this.priceFiles.length==0){
                        this.$alert('请上传报价附件', '提示', {type:'warning'});
                    } else {
                        this.save()
                    }
                }
            });
        },
        save(){
            this.$confirm('是否确定保存该报价信息？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                const params = {
                    contract_object_id: this.paramsId,
                    target_prices: this.separateAry(),
                    file_ids: this.filterFilesId()
                }
                // 保存报价附件的接口
                createdPrice(params).then((res)=>{

                    this.$confirm('保存成功！', '提示', {
                        confirmButtonText: '返回列表',
                        cancelButtonText: '继续编辑',
                        type: 'success'
                    }).then(() => {
                        this.$router.push({name: 'priceList' });
                    }).catch(() => { })

                }).catch(err => {
                    // console.log(err,'异常')
                }).finally(()=>{
                    this.pageLoading = false
                })
            }).catch(() => {    
            });
        },
      
       
        beforeUpload(file) {
            const testmsg = file.name.substring(file.name.lastIndexOf('.') + 1).toLocaleUpperCase()

            const isExcel = testmsg === 'XLS' || testmsg === 'XLSX';
            const isDoc = testmsg === 'DOC' || testmsg === 'DOCX';
            const isPPT = testmsg === 'PPT' || testmsg === 'PPTX';
            const isPDF = testmsg === 'PDF';
            const isImg = testmsg === 'JPG' || testmsg === 'JPEG' || testmsg === 'PNG';
            const isCad = testmsg === 'DWG' || testmsg === 'DXF' || testmsg === 'DWT' || testmsg === 'DWS';
            const isCompress = testmsg === 'RAR' || testmsg === 'ZIP' || testmsg === 'RAR4';
            
            const isLt8M = file.size / 1024 / 1024 < 8;

            if(!isPDF && !isExcel && !isImg && !isDoc && !isPPT && !isCad && !isCompress){
                this.$message.error('只支持上传文件格式：excel，word，ppt，pdf，图片（jpg,png）,cad图和压缩包!');
                return false
            }
             if (!isLt8M) {
                this.$message.error('上传文件大小不能超过 8MB!');
                return false
            }
        },
        uploadSuccess(res,file,fileList){
            if(res.code !== 0){
                return this.$message.error(res.msg)
            }

            this.priceFiles.push(res.data)

        },
        delFile(index){
            this.priceFiles.splice(index, 1);
        },
        //预览文件
        openFile(name, url){
            const type = name.replace(/.*\./, "").toLocaleUpperCase()
            if(type.indexOf('XLS') > -1 || type.indexOf('DOC') > -1 || type.indexOf('PPT') > -1 ){
                window.open('https://view.officeapps.live.com/op/view.aspx?src=' + url)
            } else {
                window.open(url)
            }
            /* if(type==='PDF' || type==='JPG' || type === 'JPEG' || type === 'PNG'){
                window.open(url)
            } else {
                window.open('https://view.officeapps.live.com/op/view.aspx?src=' + url)
            } */
        },

        viewRecord(){
            this.open = true
        },
    }
}
</script>

<style lang="scss" scoped>
.detail-box{
    padding: 0;
    box-shadow: none;
    .downtemplate{
        margin-left:15px;
        font-family:Arial;
        font-size:13px;
        font-weight:normal;
        font-style:normal;
        text-decoration:underline;
        color:#0099FF;
    }
    .supply-company{
        padding-right: 92px;
        position: relative;
        span{
            margin-left: 16px;
            &:first-child{
                margin-left: 10px;
            }
        }
        .el-button{
            top: 5px;
            right: 10px;
            position: absolute;
            padding:5px;
        }
    }

    .purchase-files{
        margin-right: 10px;
        &:last-child{
            margin-right: 0;
        }
    }
    ::v-deep{
        .el-table .el-form-item{
            margin-bottom: 0;
            &.is-error{
                margin-bottom: 20px;
            }
        }
    }
}
.upload-total{
    display: flex;
    margin-top: 14px;
    overflow: hidden;
    .area-upload{
        width: 578px;
    }
    .area-total{
        flex:1;
        font-size: 14px;
        line-height: 20px;
        word-break: break-all;
        text-align: right;
    }
}

.list-table{
    ::v-deep{
        .input-align_center input{
            text-align: center;
        }
        .el-input--small{
            .el-input__inner{
                padding: 0 6px;
            }
        }
        .el-select{
            .el-input__suffix{
                right: 2px;
            }
            .el-input__inner{
                padding-right: 26px;
            }
        }
        .input-small{
            
            .el-input-group__append{
                padding:0 8px;
            }
        }
    } 
}
</style>