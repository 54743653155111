<template>
    <el-dialog title="报价记录" :visible="open" width="80%" @close="canel">
        <el-table
        :data="list"
        :span-method="objectSpanMethod"
        border
        v-loading="loading"
        style="width: 100%;">
            <el-table-column prop="turn_num" label="报价轮次" align="center" width="96px">
                <template slot-scope="{row}">
                    <span>{{'第'+row.turn_num+'轮'}}</span>
                </template>
            </el-table-column>
            <el-table-column label="序号" type="index" width="54" align="center" />
            <el-table-column prop="product_name" label="品名" align="center" />
            <el-table-column prop="description" label="规格与描述" align="center" />
            <el-table-column prop="purchase_num" label="数量"  align="center" />
            <el-table-column prop="brand_name" label="品牌"  align="center" />
            <el-table-column prop="model_name" label="型号"  align="center" />
            <el-table-column prop="is_discount" label="是否抵扣" align="center" width=100>
                <template slot-scope="{row}">
                    <el-tag :type="row.is_discount===0 ? 'info' : 'success'">{{row.discount_name}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="tax_rate" label="税率" align="center" width=80>
                <template slot-scope="{row}">
                    {{row.tax_rate + '%'}}
                </template>
            </el-table-column>
            <el-table-column prop="total_price" label="总价（元）"  align="center" />
       </el-table>
    </el-dialog>
</template>
<script>
import { getPriceRecord } from "@/api";

export default {
    name:'DialogPurchase',
    props: {
        open: {
            type: Boolean,
            default: ()=> false
        },
        contractId: Number
    },
    data(){
        return {
            loading: false,
            list: [],
            spanArr:[],
        }
    },
    watch:{
        open(val){
            if(val){
                this.getData();
            }
        }
    },
    methods: {
       getData(){
            this.loading = true;
            getPriceRecord({contract_object_id:this.contractId}).then((res)=>{
                if(res.data && res.data.length > 0){
                    this.list = res.data
                    this.getSpanArr(res.data)
                }
            }).finally(()=>{
                this.loading = false
            })
       },
       getSpanArr(list){
            let pos = 0
            list.forEach((item, index) => {
                if (index === 0) {
                    this.spanArr.push(1);
                } else {
                    if(item.turn_num === list[index-1].turn_num ){
                        this.spanArr[pos] += 1;
                        this.spanArr.push(0);
                    } else {
                        this.spanArr.push(1);
                        pos = index;
                    }
                }
            });
        },
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 0) {
                const _row = this.spanArr[rowIndex];
                const _col = _row > 0 ? 1 : 0;
                return {
                    rowspan: _row,
                    colspan: _col
                }
            }
        },
        canel(){
            this.$emit('update:open', false);
        },
    }
}
</script>