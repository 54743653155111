<template>
    <div class="detail-row-item">
        <label class="detail-row-item__label" :style="labelStyle">{{label}}</label>
        <div class="detail-row-item__value">
            <slot />
        </div>
    </div>
</template>
<script>
export default {
    name: 'DetailRowItem',
    props: {
        label: String,
        labelWidth: String,
    },
    computed: {
        labelStyle() {
            const ret = {};
            const labelWidth = this.labelWidth || this.parentData.labelWidth ;
            if (labelWidth) {
                ret.width = labelWidth;
            }
            return ret;
        },
        parentData() {
            let parent = this.$parent;
            let parentName = parent.$options.componentName;
            while (parentName !== 'DetailRow') {
                parent = parent.$parent;
                parentName = parent.$options.componentName;
            }

            return parent;
        },
    }
}
</script>